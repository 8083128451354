$(document).on("turbolinks:load", function () {
  calcTotal();
});
$(document).on("change", '[id$="_kingaku"]', function(event) {
  calcTotal();
});
$(document).on("cocoon:after-remove", function() {
  calcTotal();
})

function calcTotal() {
  var total = 0;
  $('[id$="_kingaku"]').each(function () {
    var destroy_id = $(this).attr("id").replace("_kingaku", "__destroy");
    if ($("#" + destroy_id).val() != 1) {
      // console.log('[koutsuhi] add %o, %o', $(this).attr("id"), $(this).val())
      total += Number($(this).val());
    }
    // else {
    //   console.log('[koutsuhi] deleted %o', $(this).attr("id"));
    // }
  })
  // console.log('[koutsuhi] total = %o', total);
  $("#koutsuhi_shinsei_goukei").val(total);
};