// 明細が追加されたとき、その明細の sequence 番号を決める

$(document).on("cocoon:after-insert", function (event, added_obj) {
  insertMeisai(added_obj);
});

function insertMeisai(added_obj) {
  // 新しい明細が追加されたとき
  // added_obj : 追加されたオブジェクト

  // 追加された明細(tr)の中の「_sequence」オブジェクトを取得
  var $new_sequence = $(added_obj).find($('[id$="_sequence"]'));

  // 兄弟の明細の「_sequence」オブジェクトを取得
  var $brothers = $(added_obj).siblings().find($('[id$="_sequence"]'));
  // 兄弟がいない(その申請の最初の明細なら、sequence 番号は 1 にする
  if ($brothers.length == 0) {
    $new_sequence.val(1);
  }
  // 兄弟がいれば sequence 番号の最大値を探す
  else {
    var max_sequence = 0;
    $brothers.each(function () {
      var this_sequence = Number($(this).val());
      if (this_sequence > max_sequence) {
        max_sequence = this_sequence;
      }
    });
    // 新しい明細の sequence 番号を、同じ申請内の sequence 番号の最大値+1に設定する
    $new_sequence.val(Number(max_sequence) + 1);
  }
};

// 明細が削除されるとき、それ以降の明細の sequence 番号を前倒しする

$(document).on("cocoon:before-remove", function (event, deleted_obj) {
  removeMeisai(deleted_obj);
});

function removeMeisai(deleted_obj) {
  // 明細が削除されるとき
  // deleted_obj : 削除されるオブジェクト

  // 削除される明細(tr)の中の「_sequence」オブジェクトの値を取得
  var $del_sequence = $(deleted_obj).find($('[id$="_sequence"]'));
  var deleted_sequence = $del_sequence.val();
  // 削除される明細の sequence は 0 にしておく
  $del_sequence.val(0);
  // console.log("delete %o => %o", deleted_sequence, $del_sequence.val());
  // 兄弟の明細の「_sequence」オブジェクトを取得
  var $brothers = $(deleted_obj).siblings().find($('[id$="_sequence"]'));
  // 兄弟がいない(その申請の最後の明細)なら、処理終了
  if ($brothers.length == 0) {
    return;
  }
  // 兄弟がいれば sequence 番号を前倒しする
  else {
    $brothers.each(function () {
      var this_sequence = Number($(this).val());
      // console.log("%o =>", this_sequence);
      // 削除される sequence 番号より大きい場合は、
      if (this_sequence > deleted_sequence) {
        // sequence 番号から 1 引く
        $(this).val(this_sequence - 1);
        // console.log("=> %o", $(this).val());
      }
    });
  }
};

// 明細の上下ボタンがクリックされたとき、前後の明細と順番を入れ替える

$(document).on("click", ".koutuhiMeisaiUp, .koutuhiMeisaiDown", function () {
  updown_qiotation_content_list($(this));
});

function updown_qiotation_content_list(updownButton) {
  // ボタンのクラスで、"up" か "down" か
  // console.log(updownButton);
  var direction;
  if (updownButton.hasClass("koutuhiMeisaiUp")) {
    direction = "up"
  }
  else {
    direction = "down"
  }
  // console.log(direction);

  // 上下ボタンが押された行
  var this_tr = updownButton.parents('.koutsuhi_meisai');
  // console.log(this_tr);

  // 入れ替え対象の行
  var target_tr;
  if (direction == "up") {
    target_tr = this_tr.prevAll('.koutsuhi_meisai').first(); // 上ボタンがクリックされた上の行
  }
  else {
    target_tr = this_tr.nextAll('.koutsuhi_meisai').first(); // 下ボタンがクリックされた下の行
  }
  // console.log(target_tr);

  // 対象の行がなければ入れ替えを行わず終了
  if (target_tr.length == 0) {
    // console.log("cannot up/down!");
    return;
  }

  // 明細番号(sequence)の値を入れ替える
  var this_number = this_tr.find($('[id$="_sequence"]'));
  // console.log(this_number.val());
  var target_number = target_tr.find($('[id$="_sequence"]'))
  // console.log(target_number.val());
  var new_this_number = target_number.val();
  var new_target_number = this_number.val();
  this_number.val(new_this_number);
  target_number.val(new_target_number);

  // 行を入れ替える
  if (direction == "up") {
    this_tr.insertBefore(target_tr);
  }
  else {
    this_tr.insertAfter(target_tr);
  }
}
