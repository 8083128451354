$(document).on("turbolinks:load", function () {
  // console.log("[tab] active (%o) = %o", $('.tab li.active').length, $('.tab li.active'));
  if ($('.tab li.active').length == 0) {
    $('.tab li:first-of-type').addClass("active");
    $('.tab-area:first').addClass("is-active");
  }
  var hashName = location.hash;
  // console.log('[tab.js] location.hash = %o', hashName);
  GethashID(hashName);

  $(".tab").on("click", "a", function () {
    var idName = $(this).attr('href');
    // console.log('[tab a clicked] this = %o, id = %o', $(this), idName);
    GethashID(idName);
    return false;
  });

  function GethashID (hashIDName) {
    // console.log('[GethashID] hashIDName = %o', hashIDName);
    if (hashIDName) {
      $('.tab li').find('a').each(function() {
        var idName = $(this).attr('href');
        // console.log('[GethashID] idName = %o', idName);
        if (idName == hashIDName) {
          // console.log('[GethashID] match %o', hashIDName);
          var parentElm = $(this).parent();
          $('.tab li').removeClass("active");
          $(parentElm).addClass("active");
          $('.tab-area').removeClass("is-active");
          $(hashIDName).addClass("is-active");
        }
      });
    }
  };

});

