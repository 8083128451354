require("./tab");
require("./koutsuhi");
require("./accordion");
require("./koutsuhi_meisai");

$(document).on("keydown", "input", function(e) {
  if ((e.which && e.which === 13) || (e.keyCode && e.keycode === 13)) {
    return false;
  }
  else {
    return true;
  }
});